import React, { Component } from 'react';
import '../styles/App.css';
import FooterIcon from './FooterIcon';
import { github_icon, linkedin_icon, udemy_icon, mail_icon } from '../assets';

const footerIcons = [
  { href: 'https://github.com/15Dkatz', src: github_icon },
  { href: 'https://www.linkedin.com/in/david-katz-sf', src: linkedin_icon },
  { href: 'https://www.udemy.com/user/54cd8dd54e49b/', src: udemy_icon },
  { href: 'mailto:dkcodehelper@gmail.com', src: mail_icon }
]

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        {
          footerIcons.map((f, index) => {
            return (
              <FooterIcon href={f.href} src={f.src} key={index}/>
            )
          })
        }
      </div>
    )
  }
}

export default Contact;
