export default [
  {
    id: 0,
    link: 'https://www.youtube.com/watch?v=EJWR3sa4mKw&t=1s',
    title: 'The Software Engineering Acronyms You Need to Know',
    date: 'Mar 17, 2019'
  },
  {
    id: 1,
    link: 'https://youtu.be/uU6Yp09FcBE',
    title: 'Are There "Dumb" Questions in Software Engineering?',
    date: 'Mar 3, 2019'
  }
];
