import blockchainImage from './blockchain_courseImage.png';
import blockchainFullstackImage from './blockchain_fullStack_courseImage.png';
import buildEthereumImage from './buildEthereum_courseImage.png';
import dockerImage from './docker_courseImage.png';
import es6InDepthImage from './es6InDepth_courseImage.png';
import es6Image from './es6_courseImage.png';
import fullstackImage from './dragonstack_courseImage.png';
import jsBootcampImage from './javascript_bootcamp_courseImage.png';
import nextReactImage from './next_react_course_logo.png';
import nodepsqlImage from './node_psql_courseImage.png';
import pythonBlockchainImage from './python_blockchain_image.png';
import reactHooksImage from './reactHooks_courseImage.png';
import reactJSImage from './reactJS_courseImage.png';
import reactNativeImage from './reactNative_courseImage.png';
import reactBootcampImage from './reactBootcamp_courseImage.png';
import reactvrImage from './reactvr-image.png';
import reduxImage from './redux-courseImage.png';
import securityImage from './reactSecurity_courseImage.png';
import testingImage from './testing_courseImage.png';
import tddImage from './tdd_courseImage.png';
import vueImage from './vue_courseImage.jpg';

export {
  blockchainImage,
  blockchainFullstackImage,
  buildEthereumImage,
  dockerImage,
  es6InDepthImage,
  es6Image,
  fullstackImage,
  jsBootcampImage,
  pythonBlockchainImage,
  nextReactImage,
  nodepsqlImage,
  reactHooksImage,
  reactJSImage,
  reactNativeImage,
  reactvrImage,
  reactBootcampImage,
  reduxImage,
  securityImage,
  testingImage,
  tddImage,
  vueImage
};

import github_icon from './github_icon.png';
import linkedin_icon from './linkedin_icon.png';
import twitter_icon from './twitter_icon.png';
import udemy_icon from './udemy_icon.png';
import medium_icon from './medium_icon.png';
import mail_icon from './mail_icon.png';
import youtube_icon from './youtube_icon.png';

export {
  github_icon,
  linkedin_icon,
  twitter_icon,
  udemy_icon,
  medium_icon,
  mail_icon,
  youtube_icon
};
