import React, { Component } from 'react';
import TYPE_RACES from '../data/typeRaces';
import '../styles/typeRace.css';

const START_STATE = {
  userText: '',
  started: true,
  victory: false,
  errors: 0
};

class TypeRace extends Component {
  state = {
    typeRace: {},
    time: 0,
    started: false,
    failure: false,
    victory: false,
    victoryText: null
  };

  startRandomRace = () => {
    this.setState({
      ...START_STATE,
      typeRace: TYPE_RACES[Math.floor(Math.random() * TYPE_RACES.length)]
    });
  }

  selectRace = typeRace => () => {
    this.setState({ ...START_STATE, typeRace });
  }

  updateUserText = event => {
    const { value } = event.target;
    const { text } = this.state.typeRace;

    if (value.length <= 1) {
      this.setState({ time: new Date().getTime() });
    }

    if (value !== text.substring(0, value.length)) {
      this.setState({ failure: true });
    } else {
      this.setState({ failure: false });
    }

    if (value === text) {
      this.setState({ victory: true });
      this.checkRecord();
    } else {
      this.setState({ victory: false });
    }

    // set the value in order to do arbitrary clears with this.setState({ userText: '' });
    this.setState({ userText: value });
  }

  handlePaste = event => {
    // event.preventDefault();
  }

  handleKeyDown = event => {
    // Prevent CTRL-Z hack
    if (event.keyCode === 90) event.preventDefault();
  }

  checkRecord = () => {
    const raceStorageKey = `TYPE_RACE_${this.state.typeRace.id}`;
    const record = localStorage.getItem(raceStorageKey);

    const time = new Date().getTime() - this.state.time;

    if (!record || (time < Number(record))) {
      this.setState({ victoryText: `Done 🎉 ${time}ms !`});

      localStorage.setItem(raceStorageKey, time);
    } else {
      this.setState({ victoryText: `Success! ${time} milliseconds! Record: ${record} milliseconds`})
    }
  }

  render() {
    const {
      started,
      typeRace,
      userText,
      failure,
      victory,
      victoryText
    } = this.state;

    let color = failure ? ' #c62828': ' #9a9a9a';
    if (victory) color = ' #388e3c';

    return (
      <div>
        <h2>Type Race</h2>
        {
          started ? (
            <div>
              <div className='typerace-text'>{typeRace.text}</div>
              <br />
              <div>
                <input
                  onChange={this.updateUserText}
                  onPaste={this.handlePaste}
                  onKeyDown={this.handleKeyDown}
                  type='textarea'
                  style={{ color }}
                  value={userText}
                  className='typerace-input'
                />
              </div>
              <br />
              <div>{victoryText}</div>
              <br />
            </div>
          ) : null
        }
        <div>
          <button className='typerace-button' onClick={this.startRandomRace}>Start a new race!</button>
        </div>
        <br />
        <h3>Or select your favorite race</h3>
        <div>
          {
            TYPE_RACES.map(TYPE_RACE => {
              const { text } = TYPE_RACE;

              return (
                <button
                  onClick={this.selectRace(TYPE_RACE)}
                  style={{ margin: '3px' }}
                  className='typerace-button'
                  key={TYPE_RACE.id}
                >
                  {text.substring(0, 20)}...
                </button>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default TypeRace;