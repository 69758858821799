import React from 'react';
import Post from './Post';
import ARTICLES from '../data/articles';

const Articles = ({ thinView }) => {
  // fetch the articles at root index.js page and here, if they'renot coming from props
  return (
    <div className='Articles'>
      <div className='h-4'>Articles ✍</div>
      {
        ARTICLES.map(ARTICLE => <Post key={ARTICLE.id} post={ARTICLE} thinView={thinView} />)
      }
    </div>
  )
};

export default Articles;
