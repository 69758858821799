import React from 'react';

const Post = ({ post, thinView }) => {
  // TODO: add claps/likes/views from API eventually
  const { link, title, date } = post;

  return (
    <div style={{ margin: 20, display: 'flex', width: thinView ? '85%' : '750px', alignItems: 'stretch' }} className='p-medium'>
      <a
        href={link}
        target='_blank'
        style={{ textDecoration: 'none', display: 'flex', flex: 5, justifyContent: 'flex-start', textAlign: 'left' }}
      >
        {title}
      </a>
      <div style={{ display: 'flex', flex: 0.2 }}></div>
      <div style={{ display: 'flex', flex: 1 }}>{date}</div>
    </div>
  )
};

export default Post;
