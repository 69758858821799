export default [
  {
    id: 1,
    text: "To understand what recursion is... You must first understand what recursion is"
  },
  {
    id: 2,
    text: "What do you call a belt made out of watches? A waist of time."
  },
  {
    id: 3,
    text: "Where do programmers like to hangout? The Foo Bar"
  },
  {
    id: 4,
    text: "Bears, beets, battlestar galactica."
  }
];
