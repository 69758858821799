import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Header extends Component {
  state = { thinView: true };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    const thinView = window.innerWidth < 600;
    this.setState({ thinView });
  }

  render() {
    const links = (
      <span>
        <Link className="header-e h-5" to="/about">About</Link>
        <Link className="header-e h-5" to="/courses">Courses</Link>
        <Link className="header-e h-5" to="/newsletter">Newsletter</Link>
      </span>
    );


    let headerContent = (
      <div>
        <div className="header-e header-left h-5">&#123; David_Katz&#8725;&#125;</div>
        <div className="header-e header-right">{links}</div>
      </div>
    );

    if (this.state.thinView) {
      headerContent = (
        <div style={{ textAlign: 'center' }}>
          <div className="header-e">{links}</div>
        </div>
      );
    }

    return (
      <div>
        <div className="App-header">
          {headerContent}
        </div>
        <hr />
        <br />
      </div>
    );
  }
}

export default Header;
