import React, { Component } from 'react';
import Articles from './Articles';
import Contact from './Contact';
import EmailSubmit from './EmailSubmit';
import Videos from './Videos';

class NewsLetterPage extends Component {
  state = { thinView: false };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    const thinView = window.innerWidth < 760;

    this.setState({ thinView });
  }

  render() {
    return (
      <div>
        <EmailSubmit largeTitle />
        <br />
        <hr className="divider" />
        <Articles thinView={this.state.thinView} />
        <hr className="divider" />
        <Videos thinView={this.state.thinView} />
        <hr className="divider" />
        <Contact />
      </div>
    );
  }
};

export default NewsLetterPage;
