import React from 'react';

const ResumeRedirect = () => {
  return (
    <div>
      <meta
        httpEquiv="refresh"
        content="0; URL='https://docs.google.com/document/d/1prpwpKFW4PhYCo2vVOsBHTCg_zMCWUlyVBwkKisZgQ0/'"
      />
    </div>
  );
}

export default ResumeRedirect;