// TODO:
  // learn a handful of licks, and add them to the list of random shapes that can appear

import React, { useState } from 'react';

import pentatonicMajorShape1 from './pentatonic-shapes/pentatonic_major_shape_1.png';
import pentatonicMajorShape2 from './pentatonic-shapes/pentatonic_major_shape_2.png';
import pentatonicMajorShape3 from './pentatonic-shapes/pentatonic_major_shape_3.png';
import pentatonicMajorShape4 from './pentatonic-shapes/pentatonic_major_shape_4.png';
import pentatonicMajorShape5 from './pentatonic-shapes/pentatonic_major_shape_5.png';
import pentatonicMinorShape1 from './pentatonic-shapes/pentatonic_minor_shape_1.png';
import pentatonicMinorShape2 from './pentatonic-shapes/pentatonic_minor_shape_2.png';
import pentatonicMinorShape3 from './pentatonic-shapes/pentatonic_minor_shape_3.png';
import pentatonicMinorShape4 from './pentatonic-shapes/pentatonic_minor_shape_4.png';
import pentatonicMinorShape5 from './pentatonic-shapes/pentatonic_minor_shape_5.png';

const NOTES = [
  'C',
  'C#',
  'Db',
  'D',
  'D#',
  'Eb',
  'E',
  'F',
  'F#',
  'Gb',
  'G',
  'Ab',
  'A',
  'Bb',
  'B',
  'B#',
];

const PENTATONIC_SHAPES = [
  {
    title: 'Major Pentatonic Shape 1',
    image: pentatonicMajorShape1,
  },
  {
    title: 'Major Pentatonic Shape 2',
    image: pentatonicMajorShape2,
  },
  {
    title: 'Major Pentatonic Shape 3',
    image: pentatonicMajorShape3,
  },
  {
    title: 'Major Pentatonic Shape 4',
    image: pentatonicMajorShape4,
  },
  {
    title: 'Major Pentatonic Shape 5',
    image: pentatonicMajorShape5,
  },
  {
    title: 'Minor Pentatonic Shape 1',
    image: pentatonicMinorShape1,
  },
  {
    title: 'Minor Pentatonic Shape 2',
    image: pentatonicMinorShape2,
  },
  {
    title: 'Minor Pentatonic Shape 3',
    image: pentatonicMinorShape3,
  },
  {
    title: 'Minor Pentatonic Shape 4',
    image: pentatonicMinorShape4,
  },
  {
    title: 'Minor Pentatonic Shape 5',
    image: pentatonicMinorShape5,
  },
];

const selectRandomItem = ({ items }) => {
  return items[Math.floor(Math.random() * items.length)];
};

const PentatonicPrompt = () => {
  const [note, setNote] = useState(selectRandomItem({ items: NOTES }));
  const [pentatonicShape, setPentatonicShape] = useState(
    selectRandomItem({ items: PENTATONIC_SHAPES })
  );

  const chooseNext = () => {
    setNote(selectRandomItem({ items: NOTES }));
    setPentatonicShape(selectRandomItem({ items: PENTATONIC_SHAPES }));
  }

  const { image: pentatonicShapeImage, title: pentatonicShapeTitle } = pentatonicShape;

  return (
    <div className="PentatonicPrompt">
      <div>Note: {note}</div>
      <br />
      <div>{pentatonicShapeTitle}</div>
      <div>
        <img
          className="pentatonic-shape-image"
          src={pentatonicShapeImage}
          alt={pentatonicShapeTitle}
        ></img>
      </div>

      <input
        type="button"
        value="Next"
        className="next-button"
        onClick={chooseNext}
      >
      </input>
    </div>
  )
}

export default PentatonicPrompt;
