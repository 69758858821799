import React, { Component } from 'react';
import SlickSlider from './react-slick';
import '../styles/App.css';
import COURSES from '../data/courses';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 15,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
  fade: true,
  centerMode: true,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 2500
};

const sliderCourses = COURSES.filter(course => course.includeInSlider);

class CourseSlider extends Component {
  state = { linkText: sliderCourses[0].link };

  setLinkText = index => {
    this.setState({ linkText: sliderCourses[index].link });
  }

  render() {
    const { thinView, ultraThinView } = this.props;
    console.log(`!thinView`, !thinView );

    return (
      <div className='Slider'>
        <SlickSlider {...{...sliderSettings, dots: !thinView }} beforeChange={this.setLinkText} afterChange={this.setLinkText}>
          {
            sliderCourses.map(item => {
              const { title, pic, id } = item;

              let styleExtension = '';
              if (thinView) styleExtension = '_small';
              if (ultraThinView) styleExtension = '_ultra_small';

              return (
                <a className={`slide_item ${thinView ? `slide_item${styleExtension}` : ''}`} key={id} target='_blank' href={this.state.linkText}>
                  <img className={`slide_item_pic ${thinView ? `slide_item_pic${styleExtension}` : ''}`} src={pic} alt="slide-item" />
                  <p className={`slide_item_text ${thinView ? `slide_item_text${styleExtension}` : ''}`}>
                    <span className='no-link-dec'>{title}</span>
                  </p>
                </a>
              )
            })
          }
        </SlickSlider>
      </div>
    )
  }
}

export default CourseSlider;