import React from 'react';
import REVIEWS from '../data/reviews';

const Review = props => {
  const { course, studentInitials, review, image } = props.review;

  return (
    <div className='Review'>
      <div className='Review-content'>
        <div className='Review-content-top'></div>
        <div className='Review-content-middle'>
          <p>{review}</p>
          <p className='tiny-text' style={{ marginTop: 0 }}>- {studentInitials}</p>
        </div>
        <div className='Review-content-bottom'>
          <p className='tiny-text'>{course}</p>
        </div>
      </div>
      <img className="Review-img" alt={course} src={image} />
    </div>
  );
}

const Reviews = () => {
  return (
    <div className='Reviews'>
      <div className="h-5">
        Feedback from my Students
      </div>
      <p>Join 196,000 students from 192 countries!</p>
      <br />
      { REVIEWS.map(review => <Review key={review.id} review={review} />) }
    </div>
  )
}

export default Reviews;