import { tddImage, blockchainImage, reactBootcampImage, blockchainFullstackImage } from '../assets';

export default [
  {
    id: 0,
    course: 'React JS and Redux Bootcamp',
    student: 'Brian Campo',
    studentInitials: 'B. C.',
    review: 'Excellent course! David does a great job. His style and content are very engaging, and his ability to demistify things other teachers gloss over makes this course #1 in my book.',
    image: reactBootcampImage
  },
  {
    id: 1,
    course: 'Build a Blockchain and a Cryptocurrency | Full-Stack Edition',
    student: 'Diana A',
    studentInitials: 'D. A.',
    review: 'Excellent! The basic blockchain code is introduced early on. Explanations are very clear',
    image: blockchainFullstackImage
  },
  {
    id: 2,
    course: 'Build a Blockchain and a Cryptocurrency from Scratch',
    student: 'Guillaume B',
    studentInitials: 'G. B.',
    review: 'What a fantastic journey! I learned a lot about blockchain and I really appreciated the way David integrated it with Javascript... Awesome!',
    image: blockchainImage
  },
  {
    id: 3,
    course: 'Build a Blockchain and a Cryptocurrency from Scratch',
    student: 'Misha R',
    studentInitials: 'M. R.',
    review: 'Outstanding course - I have a fully working and tested blockchain thanks to this course! David was super helpful in the Q & A and did an excellent job of breaking everything down.',
    image: blockchainImage
  },
  {
    id: 4,
    course: 'React - Mastering Test Driven Development',
    student: 'Nicolas T',
    studentInitials: 'N. T.',
    review: 'Amazing course, not only serves as an approach for TDD but also refreshes a lot of React / Redux concepts on the road. Really enjoyed it, great work and thanks!',
    image: tddImage
  }
];
