// TODO: It'd be great to pull this data from a medium API
// that way, I could also return claps!
// Once the articles list is 5+, make this an individual page

// TODO: Add timestamps. Then put the title on the left. And the timestamp + claps on the right
export default [
  {
    id: 0,
    link: 'https://medium.com/@dtkatz/how-to-build-ethereum-from-scratch-b0f98095b192',
    title: 'How to Build Ethereum from Scratch',
    date: 'Aug 31, 2019'
  },
  {
    id: 1,
    link: 'https://medium.com/@dtkatz/3-ways-to-fix-the-cors-error-and-how-access-control-allow-origin-works-d97d55946d9',
    title: 'Three Ways to Fix the CORS Error — and How the Access-Control-Allow-Origin Header Works',
    date: 'Jan 28, 2019'
  },
  {
    id: 2,
    link: 'https://medium.com/@dtkatz/react-hooks-tutorial-learn-by-building-b90ec4db2b8e',
    title: 'React Hooks Tutorial — Learn by Building',
    date: 'Jan 27, 2019'
  },
  {
    id: 3,
    link: 'https://medium.com/@dtkatz/how-to-learn-fullstack-development-a-roadmap-in-charts-9b9e4aac400f',
    title: 'How to Learn Fullstack Development: A Roadmap in Charts',
    date: 'Sep 22, 2018'
  },
  {
    id: 4,
    link: 'https://medium.com/@dtkatz/use-the-blazing-fast-parcel-bundler-to-build-and-test-a-react-app-e6972a2587e1',
    title: 'Use the Blazing fast 🔥 Parcel Bundler 📦 to Build and Test a React App',
    date: 'Jan 21, 2018'
  },
  {
    id: 5,
    link: 'https://medium.com/@dtkatz/10-react-starter-project-ideas-to-get-you-coding-5b35782e1831',
    title: '10 React Starter Project Ideas to Get You Coding',
    date: 'May 26, 2017'
  }
];
