import React, { Component } from 'react';
import COURSES from '../data/courses';

const AboutCourse = ({ course, thinView }) => {
  const { title, description, pic, link } = course;

  return (
    <a className={`AboutCourse ${thinView ? 'AboutCourse-small' : ''}`} href={link} target="_blank">
      <div className={`AboutCourse-content ${thinView ? 'AboutCourse-content-small' : ''}`}>
        <div className={`AboutCourse-content-top ${thinView ? 'AboutCourse-content-top-small' : ''}`}></div>
        <div className={`AboutCourse-content-bottom ${thinView ? 'AboutCourse-content-bottom-small' : ''}`}>
          <p>{title}</p>
          <p className='tiny-text' style={{ marginTop: 0 }}>{description}</p>
        </div>
      </div>
      <img className={`AboutCourse-img ${thinView ? 'AboutCourse-img-small' : ''}`} alt={title} src={pic} />
    </a>
  );
}

class Courses extends Component {
  state = { thinView: true, tmp: 'test' };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    const thinView = window.innerWidth < 700;
    this.setState({ thinView });
  }

  render() {
    const courses = this.props.courses || COURSES;

    return (
      <div className='AboutCourses'>
        {
          courses.map(course => (
            <AboutCourse key={course.id} course={course} thinView={this.state.thinView} />)
          )
        }
      </div>
    );
  }
}

export default Courses;
