import React, {Component} from 'react';
import Contact from './Contact';
import Courses from './Courses';
import EmailSubmit from './EmailSubmit';
import '../styles/App.css';

class CoursesPage extends Component {
  render() {
    return (
      <div className='App-portfolio'>
        <div className="h-3">Online Courses</div>
        <Courses />
        <br />
        <hr className="divider" />
        <br />
        <EmailSubmit />
        <br />
        <hr className="divider" />
        <br />
        <Contact />
      </div>
    )
  }
}

export default CoursesPage;
