import React, { Component } from 'react';
import CourseSlider from './CourseSlider';
import '../styles/App.css';

const InfoPar = () => {
  return (
    <div>
      <p>⭐ Check out my online courses ⭐</p>
      <p>So far, I've made 18 on <a
        target="blank"
        href="https://www.udemy.com/user/54cd8dd54e49b/">
          the Blockchain, Ethereum, React, JavaScript, and <i>more</i>.
        </a>
      </p>
      <br />
    </div>
  )
}

class CourseInfo extends Component {
  state = { thinView: false, ultraThinView: true };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    const thinView = window.innerWidth < 535;
    const ultraThinView = window.innerWidth < 470;

    console.log('thinView', thinView);
    this.setState({ thinView, ultraThinView });
  }

  render() {
    return (
      <div className="CourseInfo">
        <div>
          <InfoPar />
          <div className="CourseSlider_container">
            <div className="CourseInfo_div" />
            <CourseSlider thinView={this.state.thinView} ultraThinView={this.state.ultraThinView} />
            <div className="CourseInfo_div" />
          </div>
          <br />
        </div>
      </div>
    )
  }
}

export default CourseInfo;