import React from 'react';
import Courses from './Courses';
import COURSES from '../data/courses';
import { Link } from 'react-router-dom';

const AboutCourses = () => {
  return (
    <div className='AboutCourses'>
      <div className="h-5">⭐ 🏆 ⭐</div>
      <div className="h-5">Highlighted Courses</div>
      <Courses courses={COURSES.filter(course => course.highlighted)} />
      <p><Link to='/courses'>Check out the rest!</Link></p>
    </div>
  );
}

export default AboutCourses;