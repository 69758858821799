import React from 'react';
import profile from '../assets/800_x_800_profile.png';
import Contact from './Contact';
import CourseInfo from './CourseInfo';
import Reviews from './Reviews';
import EmailSubmit from './EmailSubmit';
import AboutCourses from './AboutCourses';
import '../styles/App.css';

const About = () => {
  return (
    <div className="App">
      <div className="About">
        <img src={profile} className="profile" alt="[hip, hip]"/>
        <div className="intro">
          <div className="h-3 fade-5">Hello world</div>
          <div className="About_info">
            <p>I'm David. Software engineer, and course creator.</p>
            <p>With my time, I focus on making high quality learning experiences.</p>
          </div>
          <br/>
          <hr className="divider" />
          <br/>
          <CourseInfo />
          <br/>
          <hr className="divider" />
          <Reviews />
          <br/>
          <hr className="divider" />
          <AboutCourses />
          <hr className="divider" />
          <br />
          <EmailSubmit />
          <br/>
          <hr className="divider" />
          <br/>
          <p>Reach out! I'm always happy to chat.</p>
        </div>
      </div>
      <Contact />
    </div>
  )
}

export default About;
