import React, { Component } from 'react';
import '../styles/App.css';

class FooterIcon extends Component {
  render() {
    return (
      <div className="inline">
        <a target="_blank" href={this.props.href}>
          <img src={this.props.src} className="footer_icon" alt="icon"></img>
        </a>
      </div>
    )
  }
}

export default FooterIcon;
