import React, { Component } from 'react';
import { Dots } from '@zendeskgarden/react-loaders';
import '../styles/App.css';

class EmailSubmit extends Component {
  state = { email: '', message: '', fetching: false, displayDots: false };

  submitToMailchimp = () => {
    this.dotsTimeout = setTimeout(() => {
      this.setState({ displayDots: true });
    }, 250);

    this.setState({ fetching: true, message: '' });

    // Use in dev, along with the CORS plugin: fetch('https://mail-subscriber-dtk.herokuapp.com/subscribe', {
    fetch('https://mailchimp-subscriber.appspot.com/subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email_address: this.state.email })
    })
    .then(response => response.json())
    .then(json => this.setState({ displayDots: false, fetching: false, message: json.message }));
  }

  componentWillUnmount() {
    if (this.dotInterval) clearInterval(this.dotInterval);
  }

  render() {
    return (
      <div className="App">
        <div>
          <div className={this.props.largeTitle ? 'h-4' : 'h-5'}>
            Get updated when I create new content
          </div>
          <br />
          <div>
            <input
              type="email"
              placeholder="email"
              className="p inline email-field"
              onChange={event => this.setState({ email: event.target.value })}
            />
            <button className="p inline app-button" onClick={this.submitToMailchimp}>
              submit
            </button>
          </div>
        </div>
        {
          this.state.message !== '' ? <div class="p">{this.state.message}</div> : null
        }
        {
          this.state.fetching && this.state.displayDots ? (
            <div>
              <br />
              <Dots delayMS={250} />
            </div>
          ) : null
        }
      </div>
    )
  }
}

export default EmailSubmit;
