import React from 'react';
import Post from './Post';
import VIDEOS from '../data/videos';

// TODO: Create a common class between videos and articles
// abstract out the style into the css
const Videos = ({ thinView }) => {
  return (
    <div className='Videos'>
      <div className='h-4'>Videos 🎥</div>
      {
        VIDEOS.map(VIDEO => <Post key={VIDEO.id} post={VIDEO} thinView={thinView} />)
      }
    </div>
  )
}

export default Videos;
